var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.closeDetail },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                ],
                1
              ),
              _vm.api.isLoading
                ? _c("v-skeleton-loader", {
                    ref: "skeleton",
                    attrs: { type: "table" },
                  })
                : _vm._e(),
              !_vm.api.isLoading && _vm.notification != null
                ? _c(
                    "v-card",
                    { staticClass: "pt-3 pa-3" },
                    [
                      _c(
                        "v-toolbar",
                        {
                          attrs: {
                            color: "secondary white--text text-h4 px-2",
                          },
                        },
                        [_vm._v(" Notification ")]
                      ),
                      _c(
                        "v-card-title",
                        { attrs: { color: "grey" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { size: "80", color: "grey" } },
                            [_vm._v(" mdi-account ")]
                          ),
                          _c("v-col", { staticClass: "px-3" }, [
                            _c(
                              "div",
                              { staticClass: "text-h5 font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(this.notification.fname) +
                                    " " +
                                    _vm._s(this.notification.lname) +
                                    " "
                                ),
                              ]
                            ),
                            _c("div", [
                              _vm._v(
                                " " + _vm._s(this.notification.email) + " "
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                " " + _vm._s(this.notification.mobile) + " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-card-title",
                        { staticClass: "font-weight-bold text-h5" },
                        [_vm._v(" " + _vm._s(this.notification.title) + " ")]
                      ),
                      _c(
                        "v-card-subtitle",
                        { staticClass: "font-weight-bold pt-3" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                this.notification.created_at.split("T")[0]
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-card-text",
                        {},
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "PageDemonstrationUpdate",
                                  params: {
                                    id: JSON.parse(_vm.notification.data).id,
                                  },
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "text-h6" }, [
                                _vm._v(" View Detail "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "font-weight-bold text-h6 pt-3" },
                        [
                          _vm.notification.company_id != 33
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "PageCompaniesDetail",
                                      params: {
                                        id: _vm.notification.company_id,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.notification.company_name) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("hr"),
                      _c(
                        "v-container",
                        [
                          _vm.notification.type == "VisionFormAttachment"
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-card-title", [
                                        _c("div", { staticClass: "text-h4" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                this.description.attachment_type
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                      _c("v-card-title", [
                                        _c("div", { staticClass: "text-h6" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: this.description
                                                  .attachment_url,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    this.description
                                                      .attachment_name
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.notification.type == "VisionFormPayment"
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-card-title", [
                                        _c("div", { staticClass: "text-h6" }, [
                                          _vm._v(" Payment status : "),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold text-h6 px-2",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    this.description.status
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _vm.description.total_amount != null
                                        ? _c("v-card-title", [
                                            _c(
                                              "div",
                                              { staticClass: "text-h6" },
                                              [_vm._v(" Amount : ")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-h6 px-2",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    " RM " +
                                                      _vm._s(
                                                        this.description
                                                          .total_amount
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      this.description.payment_method != null
                                        ? _c("v-card-title", [
                                            _c(
                                              "div",
                                              { staticClass: "text-h6" },
                                              [_vm._v(" Payment method : ")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-h6 px-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      this.description
                                                        .payment_method
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      this.description.invoice_no != null
                                        ? _c("v-card-title", [
                                            _c(
                                              "div",
                                              { staticClass: "text-h6" },
                                              [_vm._v(" Invoice No. : ")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-h6 px-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      this.description
                                                        .invoice_no
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c("v-card-title", [
                                        _vm._v(" Remark : "),
                                      ]),
                                      _vm.description.remark != null
                                        ? _c("mavon-editor", {
                                            staticStyle: {
                                              "z-index": "0",
                                              "min-height": "100px",
                                              height: "auto",
                                              width: "100%",
                                            },
                                            attrs: {
                                              width: "auto",
                                              defaultOpen: "preview",
                                              toolbarsFlag: false,
                                              subfield: false,
                                              language: "en",
                                              editable: false,
                                            },
                                            model: {
                                              value: _vm.description.remark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.description,
                                                  "remark",
                                                  $$v
                                                )
                                              },
                                              expression: "description.remark",
                                            },
                                          })
                                        : _c("mavon-editor", {
                                            staticStyle: {
                                              "z-index": "0",
                                              "min-height": "100px",
                                              height: "auto",
                                              width: "100%",
                                            },
                                            attrs: {
                                              height: "auto",
                                              width: "auto",
                                              defaultOpen: "preview",
                                              toolbarsFlag: false,
                                              subfield: false,
                                              language: "en",
                                              editable: false,
                                            },
                                            model: {
                                              value: _vm.isRemarkNull,
                                              callback: function ($$v) {
                                                _vm.isRemarkNull = $$v
                                              },
                                              expression: "isRemarkNull",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.notification.type == "VisionFormRenewal"
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-card-title", [
                                        _vm._v(" Renewal status : "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bold px-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  this.description.status
                                                ) +
                                                " ( " +
                                                _vm._s(
                                                  this.description
                                                    .renewal_status
                                                ) +
                                                " ) "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm.description.remark != null
                                        ? _c("mavon-editor", {
                                            staticStyle: {
                                              "z-index": "0",
                                              "min-height": "100px",
                                              height: "auto",
                                              width: "100%",
                                            },
                                            attrs: {
                                              width: "auto",
                                              defaultOpen: "preview",
                                              toolbarsFlag: false,
                                              subfield: false,
                                              language: "en",
                                              editable: false,
                                            },
                                            model: {
                                              value: _vm.description.remark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.description,
                                                  "remark",
                                                  $$v
                                                )
                                              },
                                              expression: "description.remark",
                                            },
                                          })
                                        : _c("mavon-editor", {
                                            staticStyle: {
                                              "z-index": "0",
                                              "min-height": "100px",
                                              height: "auto",
                                              width: "100%",
                                            },
                                            attrs: {
                                              height: "auto",
                                              width: "auto",
                                              defaultOpen: "preview",
                                              toolbarsFlag: false,
                                              subfield: false,
                                              language: "en",
                                              editable: false,
                                            },
                                            model: {
                                              value: _vm.isRemarkNull,
                                              callback: function ($$v) {
                                                _vm.isRemarkNull = $$v
                                              },
                                              expression: "isRemarkNull",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.notification.action != null
                            ? _c(
                                "v-card",
                                {
                                  staticClass: "pt-3 mt-3 pa-3",
                                  attrs: { elevation: "4" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-card-title", [
                                            _vm._v(" Action required : "),
                                          ]),
                                          _c("mavon-editor", {
                                            staticStyle: {
                                              "z-index": "0",
                                              "min-height": "100px",
                                              height: "auto",
                                              width: "100%",
                                            },
                                            attrs: {
                                              height: "auto",
                                              width: "auto",
                                              defaultOpen: "preview",
                                              toolbarsFlag: false,
                                              subfield: false,
                                              language: "en",
                                              editable: false,
                                            },
                                            model: {
                                              value: _vm.notification.action,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.notification,
                                                  "action",
                                                  $$v
                                                )
                                              },
                                              expression: "notification.action",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }